.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;

  .postsList {
    position: sticky;
    top: 10vh;
    align-self: flex-start;
    flex-basis: 33%;
  }

  .postDetails {
    flex-basis: 66%;
  }
}
