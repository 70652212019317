.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;

  .logoContainer {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }

  .inner {
    width: 25rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    padding: 0 4rem;
  }
}
